import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  Card, 
  CardContent, 
  Grid, 
  List, 
  ListItem, 
  ListItemText,
  CircularProgress,
  Button,
  Alert,
  Checkbox,
  FormControlLabel
} from '@mui/material';
import { useDataConnect } from '../../hooks/useDataConnect';
import { getFirestore, doc, getDoc, setDoc } from 'firebase/firestore';
import { getApp } from 'firebase/app';

const MetadataSection = ({ title, items, selectedItems, onSelectionChange }) => (
  <Card sx={{ height: '100%' }}>
    <CardContent>
      <Typography variant="h6" gutterBottom>
        {title} ({items.length})
      </Typography>
      <List dense sx={{ maxHeight: 400, overflow: 'auto' }}>
        {items.map((item, index) => (
          <ListItem key={index}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedItems.includes(item.name || item)}
                  onChange={(e) => onSelectionChange(item.name || item, e.target.checked)}
                />
              }
              label={
                <ListItemText 
                  primary={item.name || item}
                  secondary={item.count ? `${item.count.toLocaleString()} bookings` : null}
                />
              }
            />
          </ListItem>
        ))}
      </List>
    </CardContent>
  </Card>
);

const MetadataDisplay = ({ onFiltersChange, onRunQuery }) => {
  const [metadata, setMetadata] = useState({
    agents: [],
    types: [],
    options: [],
    locations: []
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [lastUpdated, setLastUpdated] = useState(null);
  const { executeQuery, ready } = useDataConnect();
  const db = getFirestore(getApp());
  const [selectedFilters, setSelectedFilters] = useState({
    agents: [],
    types: [],
    options: [],
    locations: []
  });

  useEffect(() => {
    const initializeMetadata = async () => {
      console.log('🔄 Initializing metadata...', { ready });
      if (!ready) {
        console.log('⏳ Waiting for DataConnect to be ready');
        return;
      }
      
      console.log('🔄 Fetching fresh metadata...');
      await fetchAndCacheMetadata();
    };

    initializeMetadata();
  }, [ready]);

  const fetchAndCacheMetadata = async () => {
    try {
      setLoading(true);
      console.log('🚀 Starting metadata fetch...');
      
      if (!ready) {
        console.log('❌ DataConnect not ready yet');
        return;
      }

      console.log('📡 Executing FetchMetadata query...');
      const result = await executeQuery('FetchMetadata');
      console.log('📊 Raw query result:', result);

      if (result?.rows) {
        console.log('🔍 Direct SQL rows:', result.rows);
      }

      if (!result?.data?.bookingMetadatas) {
        console.error('❌ Invalid response structure:', {
          hasData: !!result?.data,
          dataKeys: Object.keys(result?.data || {}),
          fullResult: result
        });
        throw new Error('Invalid metadata response');
      }

      const metadata = result.data.bookingMetadatas;
      console.log('📋 Metadata entries:', metadata.length);
      console.log('🔍 First few entries:', metadata.slice(0, 3));

      console.log('🔄 Processing metadata...');
      const uniqueValues = metadata.reduce((acc, item) => {
        if (item.agent) {
          acc.agents.add({
            name: item.agent.trim(),
            count: item.bookingCount || 0
          });
        }
        if (item.type) acc.types.add(item.type.trim());
        if (item.option) acc.options.add(item.option.trim());
        if (item.location) acc.locations.add(item.location.trim());
        return acc;
      }, {
        agents: new Set(),
        types: new Set(),
        options: new Set(),
        locations: new Set()
      });

      const processedMetadata = {
        agents: [...uniqueValues.agents].sort((a, b) => a.name.localeCompare(b.name)),
        types: [...uniqueValues.types].sort(),
        options: [...uniqueValues.options].sort(),
        locations: [...uniqueValues.locations].sort()
      };

      console.log('✅ Final processed metadata:', {
        agentCount: processedMetadata.agents.length,
        typeCount: processedMetadata.types.length,
        optionCount: processedMetadata.options.length,
        locationCount: processedMetadata.locations.length,
        allAgents: processedMetadata.agents.map(a => a.name)
      });

      setMetadata(processedMetadata);
      setLastUpdated(new Date().toISOString());

    } catch (err) {
      console.error('❌ Error in fetchAndCacheMetadata:', {
        error: err,
        message: err.message,
        stack: err.stack
      });
      setError(err.message);
    } finally {
      setLoading(false);
      console.log('🏁 Fetch operation completed');
    }
  };

  const handleSelectionChange = (section) => (item, isSelected) => {
    const newFilters = {
      ...selectedFilters,
      [section]: isSelected 
        ? [...selectedFilters[section], item]
        : selectedFilters[section].filter(i => i !== item)
    };
    setSelectedFilters(newFilters);
    onFiltersChange?.(newFilters);
  };

  const handleRunQuery = () => {
    console.log('=== EXECUTING FILTERED BOOKING QUERY ===');
    console.log('Query: FilteredBookingStats');
    console.log('Parameters:', {
      agents: selectedFilters.agents,
      types: selectedFilters.types,
      options: selectedFilters.options,
      locations: selectedFilters.locations
    });
    console.log('SQL equivalent (approximate):');
    console.log(`
      SELECT 
        date,
        SUM(pax) as pax,
        SUM(amount) as amount
      FROM booking
      WHERE 
        ${selectedFilters.agents.length ? `agent IN ('${selectedFilters.agents.join("','")}')` : '1=1'}
        ${selectedFilters.types.length ? `AND type IN ('${selectedFilters.types.join("','")}')` : ''}
        ${selectedFilters.options.length ? `AND option IN ('${selectedFilters.options.join("','")}')` : ''}
        ${selectedFilters.locations.length ? `AND location IN ('${selectedFilters.locations.join("','")}')` : ''}
      GROUP BY date
      ORDER BY date ASC
    `);
    console.log('=====================================');

    onRunQuery?.(selectedFilters);
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Alert severity="error" sx={{ m: 2 }}>
        {error}
      </Alert>
    );
  }

  return (
    <Box sx={{ p: 2 }}>
      <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h5">Booking Metadata</Typography>
        <Box>
          <Button 
            variant="contained" 
            onClick={fetchAndCacheMetadata}
            sx={{ mr: 2 }}
          >
            Refresh Data
          </Button>
          <Button 
            variant="contained" 
            color="primary"
            onClick={handleRunQuery}
            disabled={!Object.values(selectedFilters).some(arr => arr.length > 0)}
            sx={{ mr: 2 }}
          >
            Run Query
          </Button>
          {lastUpdated && (
            <Typography variant="caption" color="text.secondary">
              Last updated: {new Date(lastUpdated).toLocaleString()}
            </Typography>
          )}
        </Box>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <MetadataSection 
            title="Agents" 
            items={metadata.agents} 
            selectedItems={selectedFilters.agents}
            onSelectionChange={handleSelectionChange('agents')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <MetadataSection 
            title="Types" 
            items={metadata.types}
            selectedItems={selectedFilters.types}
            onSelectionChange={handleSelectionChange('types')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <MetadataSection 
            title="Options" 
            items={metadata.options}
            selectedItems={selectedFilters.options}
            onSelectionChange={handleSelectionChange('options')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <MetadataSection 
            title="Locations" 
            items={metadata.locations}
            selectedItems={selectedFilters.locations}
            onSelectionChange={handleSelectionChange('locations')}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default MetadataDisplay; 
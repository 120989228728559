import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { db } from 'config/firebase/firebase';
import { doc, getDoc } from 'firebase/firestore';
import TourCard from '../../components/TourCard';
import { Grid, Container, Box, Typography, CircularProgress, useTheme, useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom'; // Corrected import for routing
import { Card } from '@mui/material'; // Add this import at the top of the file

function LocalDeals() {
  const [tours, setTours] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [headerImage, setHeaderImage] = useState('/images/Curacao_Tours_Local_Tours.jpg'); // Set default header image
  const locationIds = [ "327479", "296972"];
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    const fetchToursByIds = async (ids) => {
      const tours = [];
      for (const id of ids) {
        const docRef = doc(db, 'activityDetails', id);
        try {
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            let tourData = docSnap.data();
            // Fetch additional details from rewrittenTourDescriptions
            const rewrittenRef = doc(db, 'rewrittenTourDescriptions', id);
            const rewrittenSnap = await getDoc(rewrittenRef);
            if (rewrittenSnap.exists()) {
              const rewrittenData = rewrittenSnap.data();
              // Override or add the necessary fields from rewrittenTourDescriptions
              tourData.snippet = rewrittenData.snippet; // Ensure this matches the field name in Firestore
              tourData.duration = rewrittenData.duration;
              tourData.days = rewrittenData.days;
            }
            tours.push({ id: docSnap.id, ...tourData });
          } else {
            console.error("No document found for ID:", id);
          }
        } catch (error) {
          console.error("Error fetching tour details:", error);
          setError('Failed to fetch tour details. Please try again later.');
        }
      }
      setTours(tours);
      setLoading(false);
    };

    // Comment out or remove this function call to prevent dynamic updates to the header image
    // fetchHeaderAndGalleryImages();
    fetchToursByIds(locationIds);
  }, []);



  return (
    <Container maxWidth="lg" sx={{ mt: { xs: 2, md: 4 }, mb: 8 }}>
      <Helmet>
        <title>Exclusive Local Deals for Curacao Residents - Curacao Tours</title>
        <meta name="description" content="Unlock exclusive local deals and special offers for Curacao residents. Dive into unforgettable experiences with our tailored tours. Don't miss out, book now!" />
      </Helmet>

      {/* Hero Section */}
      <Box sx={{ 
        width: '100vw',
        position: 'relative',
        left: '50%',
        right: '50%',
        marginLeft: '-50vw',
        marginRight: '-50vw',
        height: { xs: '40vh', md: '60vh' },
        mb: { xs: 6, md: 8 },
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#f5f5f5'
      }}>
        <Card sx={{
          width: '100%',
          height: '100%',
          maxWidth: '1600px',
          borderRadius: { xs: 0, md: '16px' },
          overflow: 'hidden',
          boxShadow: 3,
        }}>
          <Box sx={{ 
            position: 'relative', 
            width: '100%', 
            height: '100%',
            '&::after': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: 'linear-gradient(to bottom, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.1) 100%)',
              zIndex: 1
            }
          }}>
            <img
              src={headerImage}
              alt="Local Deals Header"
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                objectPosition: 'center',
              }}
            />
          </Box>
        </Card>
      </Box>

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
          <CircularProgress />
        </Box>
      ) : error ? (
        <Typography variant="h6" sx={{ textAlign: 'center', my: 4 }}>{error}</Typography>
      ) : (
        <>
          <Typography 
            variant="h4" 
            component="h1" 
            sx={{ 
              textAlign: 'center', 
              fontWeight: 'bold',
              fontSize: { xs: '1.75rem', md: '2.5rem' },
              mb: { xs: 4, md: 6 },
              mt: { xs: 2, md: 0 },
              color: '#1a237e',
              lineHeight: 1.4
            }}
          >
            DO YOU HAVE A VALID SEDULA?<br />BOOK OUR LOCAL SPECIALS NOW!
          </Typography>

          <Box sx={{ mb: { xs: 6, md: 8 } }}>
            <Grid container spacing={{ xs: 2, md: 4 }}>
              {tours.map((tour) => (
                <Grid item key={tour.id} xs={12} sm={6} md={4}>
                  <Link to={`/tour/${tour.id}`} style={{ textDecoration: 'none' }}>
                    <TourCard tour={tour} />
                  </Link>
                </Grid>
              ))}
            </Grid>
          </Box>
        </>
      )}
    </Container>
  );
}

export default LocalDeals;

import React, { useState, useEffect } from 'react';
import { 
  Box, Typography, CircularProgress, Alert, Button, TextField, Dialog,
  DialogTitle, DialogContent, DialogActions 
} from '@mui/material';
import { 
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, 
  ResponsiveContainer, Legend 
} from 'recharts';
import { useDataConnect } from '../../hooks/useDataConnect';
import { getFirestore, doc, setDoc } from 'firebase/firestore';

const FilteredBookingChart = ({ filters }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);
  const [chartName, setChartName] = useState('');
  const { executeQuery, ready, initialized } = useDataConnect();
  const db = getFirestore();

  const fetchFilteredData = async () => {
    console.log('Fetch filtered data called');
    console.log('DataConnect ready:', ready);

    if (!ready) {
      console.log('DataConnect not ready yet, waiting...');
      return;
    }

    try {
      setLoading(true);
      console.log('Fetching filtered data with params:', filters);
      
      const queryFilters = {
        agents: filters.agents.length ? filters.agents : null,
        types: filters.types.length ? filters.types : null,
        options: filters.options.length ? filters.options : null,
        locations: filters.locations.length ? filters.locations : null
      };

      console.log('Executing query with filters:', queryFilters);
      
      const result = await executeQuery('FilteredBookingStats', queryFilters);
      
      console.log('Raw query result:', result);
      
      if (!result?.data?.bookings) {
        console.error('Invalid response structure:', result);
        throw new Error('Invalid response structure from query');
      }
      
      const transformedData = transformDataForChart(result.data);
      console.log('Transformed data:', transformedData);
      
      setData(transformedData);
    } catch (err) {
      console.error('Error fetching filtered data:', {
        error: err,
        message: err.message,
        stack: err.stack
      });
      setError(`Error fetching data: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  const transformDataForChart = (rawData) => {
    console.log('Starting data transformation with:', rawData);
    
    if (!rawData?.bookings || !Array.isArray(rawData.bookings)) {
      console.error('Invalid data structure in transform:', rawData);
      return [];
    }

    try {
      // Group data by date
      const groupedByDate = rawData.bookings.reduce((acc, booking) => {
        const date = booking.date;
        if (!acc[date]) {
          acc[date] = {
            date,
            pax: 0,
            amount: 0
          };
        }
        acc[date].pax += Number(booking.pax) || 0;
        acc[date].amount += Number(booking.amount) || 0;
        return acc;
      }, {});

      console.log('Grouped data:', groupedByDate);

      // Convert to array and sort by date
      const sortedData = Object.values(groupedByDate)
        .sort((a, b) => new Date(a.date) - new Date(b.date))
        .map(item => ({
          ...item,
          date: new Date(item.date).toLocaleDateString(),
          amount: Number(item.amount.toFixed(2))
        }));

      console.log('Final transformed data:', sortedData);
      return sortedData;
    } catch (err) {
      console.error('Error in data transformation:', err);
      return [];
    }
  };

  const handleSaveChart = async () => {
    try {
      await setDoc(doc(db, 'savedCharts', chartName), {
        name: chartName,
        filters,
        data,
        createdAt: new Date().toISOString()
      });
      setSaveDialogOpen(false);
    } catch (err) {
      setError('Failed to save chart: ' + err.message);
    }
  };

  useEffect(() => {
    console.log('FilteredBookingChart useEffect triggered');
    console.log('Filters:', filters);
    console.log('DataConnect ready:', ready);

    if (ready && Object.values(filters).some(arr => arr.length > 0)) {
      console.log('Conditions met, fetching data...');
      fetchFilteredData();
    } else {
      console.log('Conditions not met:', {
        ready,
        hasFilters: Object.values(filters).some(arr => arr.length > 0)
      });
    }
  }, [filters, ready]);

  if (!ready) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
        <CircularProgress />
        <Typography sx={{ ml: 2 }}>Initializing DataConnect...</Typography>
      </Box>
    );
  }

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
        <CircularProgress />
        <Typography sx={{ ml: 2 }}>Loading data...</Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Alert severity="error" sx={{ m: 2 }}>
        {error}
      </Alert>
    );
  }

  return (
    <Box sx={{ width: '100%', height: 400 }}>
      <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h6">Filtered Booking Data</Typography>
        <Button 
          variant="contained" 
          onClick={() => setSaveDialogOpen(true)}
          disabled={!data.length}
        >
          Save Chart
        </Button>
      </Box>

      {data.length > 0 ? (
        <ResponsiveContainer>
          <LineChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="pax" name="Passengers" stroke="#8884d8" />
            <Line type="monotone" dataKey="amount" name="Amount" stroke="#82ca9d" />
          </LineChart>
        </ResponsiveContainer>
      ) : (
        <Box sx={{ 
          height: '100%', 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'center' 
        }}>
          <Typography>No data available for selected filters</Typography>
        </Box>
      )}

      <Dialog open={saveDialogOpen} onClose={() => setSaveDialogOpen(false)}>
        <DialogTitle>Save Chart</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Chart Name"
            fullWidth
            value={chartName}
            onChange={(e) => setChartName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSaveDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleSaveChart} disabled={!chartName}>Save</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default FilteredBookingChart;
import React from 'react';
import { Helmet } from 'react-helmet';
import {
  Container, Grid, Typography, TextField, Button, Box, Paper, useTheme, useMediaQuery, Link
} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LocationOnIcon from '@mui/icons-material/LocationOn';

function ContactPage() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const googleMapsUrl = "https://www.google.com/maps/place/Seru+di+Mahuma+18-20,+Willemstad,+Curaçao/";

  // Define our color palette
  const colors = {
    primary: '#3CB4E5',
    secondary: '#66C1E8',
    background: '#F8FCFF',
    text: '#2C3E50',
    accent: '#1a237e'
  };

  return (
    <Box sx={{ 
      backgroundColor: colors.background, 
      minHeight: '100vh',
      pt: { xs: 2, md: 4 },
      pb: { xs: 6, md: 8 }
    }}>
      <Container maxWidth="lg">
        <Helmet>
          <title>Contact Us - Curacao Tours</title>
          <meta name="description" content="Get in touch with Curacao Tours for all your travel needs. We're here to help plan your perfect Curacao experience." />
        </Helmet>

        {/* Hero Section */}
        <Box sx={{ 
          width: '100vw',
          position: 'relative',
          left: '50%',
          right: '50%',
          marginLeft: '-50vw',
          marginRight: '-50vw',
          height: { xs: '40vh', md: '50vh' },
          mb: { xs: 6, md: 8 },
          overflow: 'hidden',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#f5f5f5'
        }}>
          <Box sx={{ 
            width: '86%',
            height: '100%',
            maxWidth: '1600px',
            position: 'relative',
            borderRadius: { xs: 2, md: 4 },
            overflow: 'hidden',
            boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
            '&::after': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: 'linear-gradient(to bottom, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.1) 100%)',
              zIndex: 1
            }
          }}>
            <img
              src="/images/Curacao_Tours_Contact.jpg"
              alt="FBTT Travel Curacao Office"
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                objectPosition: 'center',
              }}
            />
          </Box>
        </Box>

        <Typography 
          variant="h2" 
          component="h1" 
          align="center" 
          sx={{ 
            color: colors.accent,
            fontWeight: 'bold',
            fontSize: { xs: '2rem', md: '3rem' },
            mb: { xs: 4, md: 6 },
            lineHeight: 1.2
          }}
        >
          Contact Us
        </Typography>

        <Grid container spacing={{ xs: 3, md: 6 }}>
          <Grid item xs={12} md={6}>
            <Paper 
              elevation={0} 
              sx={{ 
                p: { xs: 3, md: 4 },
                backgroundColor: 'white',
                borderRadius: 2,
                boxShadow: '0 8px 32px 0 rgba(0,0,0,0.1)',
                height: '100%',
                transition: 'transform 0.2s ease-in-out',
                '&:hover': {
                  transform: 'translateY(-4px)',
                }
              }}
            >
              <Typography 
                variant="h4" 
                sx={{ 
                  color: colors.text,
                  fontWeight: 'bold',
                  mb: 4,
                  fontSize: { xs: '1.5rem', md: '2rem' }
                }}
              >
                Get in Touch
              </Typography>

              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 2 }}>
                  <LocationOnIcon sx={{ color: colors.primary, mt: 0.5 }} />
                  <Box>
                    <Typography variant="body1" sx={{ color: colors.text, mb: 1 }}>
                      Seru di Mahuma #18 - 20, Willemstad, Curaçao
                    </Typography>
                    <Link 
                      href={googleMapsUrl} 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      sx={{ 
                        color: colors.primary,
                        textDecoration: 'none',
                        '&:hover': {
                          textDecoration: 'underline'
                        }
                      }}
                    >
                      View on Google Maps
                    </Link>
                  </Box>
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <PhoneIcon sx={{ color: colors.primary }} />
                  <Typography variant="body1" sx={{ color: colors.text }}>
                    +5999 8699559
                  </Typography>
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <WhatsAppIcon sx={{ color: colors.primary }} />
                  <Typography variant="body1" sx={{ color: colors.text }}>
                    +5999 5146060
                  </Typography>
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <EmailIcon sx={{ color: colors.primary }} />
                  <Typography variant="body1" sx={{ color: colors.text }}>
                    info@curacao-tours.com
                  </Typography>
                </Box>
              </Box>
            </Paper>
          </Grid>

          <Grid item xs={12} md={6}>
            <Paper 
              elevation={0} 
              sx={{ 
                p: { xs: 3, md: 4 },
                backgroundColor: 'white',
                borderRadius: 2,
                boxShadow: '0 8px 32px 0 rgba(0,0,0,0.1)',
                height: '100%',
                transition: 'transform 0.2s ease-in-out',
                '&:hover': {
                  transform: 'translateY(-4px)',
                }
              }}
            >
              <Typography 
                variant="h4" 
                sx={{ 
                  color: colors.text,
                  fontWeight: 'bold',
                  mb: 4,
                  fontSize: { xs: '1.5rem', md: '2rem' }
                }}
              >
                Send Us a Message
              </Typography>

              <form noValidate autoComplete="off">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField 
                      fullWidth 
                      label="Name" 
                      variant="outlined" 
                      required 
                      sx={{ 
                        '& .MuiOutlinedInput-root': {
                          '&:hover fieldset': {
                            borderColor: colors.primary,
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField 
                      fullWidth 
                      label="Email Address" 
                      variant="outlined" 
                      required 
                      sx={{ 
                        '& .MuiOutlinedInput-root': {
                          '&:hover fieldset': {
                            borderColor: colors.primary,
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField 
                      fullWidth 
                      label="Phone Number" 
                      variant="outlined"
                      sx={{ 
                        '& .MuiOutlinedInput-root': {
                          '&:hover fieldset': {
                            borderColor: colors.primary,
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField 
                      fullWidth 
                      label="Subject" 
                      variant="outlined"
                      sx={{ 
                        '& .MuiOutlinedInput-root': {
                          '&:hover fieldset': {
                            borderColor: colors.primary,
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField 
                      fullWidth 
                      label="Message" 
                      variant="outlined" 
                      required 
                      multiline 
                      rows={4}
                      sx={{ 
                        '& .MuiOutlinedInput-root': {
                          '&:hover fieldset': {
                            borderColor: colors.primary,
                          },
                        },
                      }}
                    />
                  </Grid>
                </Grid>

                <Button 
                  variant="contained" 
                  size="large"
                  fullWidth
                  sx={{ 
                    mt: 4,
                    py: 1.5,
                    backgroundColor: colors.primary,
                    fontWeight: 600,
                    fontSize: '1rem',
                    '&:hover': {
                      backgroundColor: colors.accent,
                    }
                  }}
                >
                  Send Message
                </Button>
              </form>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default ContactPage;

import React, { useEffect, useRef, useState, lazy, Suspense } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Typography, Button, Skeleton } from '@mui/material';
import { Global, css } from '@emotion/react';

const VideoBanner = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [isVideoVisible, setIsVideoVisible] = useState(false);

  useEffect(() => {
    // Only load video on home page
    if (location.pathname === '/') {
      setIsVideoVisible(true);
    } else {
      setIsVideoVisible(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting && videoRef.current) {
          videoRef.current.load(); // Start loading the video
          observer.unobserve(entry.target);
        }
      });
    }, options);

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, [isVideoVisible]);

  const handleLoadedData = () => {
    setVideoLoaded(true);
  };

  const handleBookNowClick = () => {
    navigate('/');
    setTimeout(() => {
      const section = document.getElementById('target-section');
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }, 0);
  };

  if (location.pathname !== '/') {
    return null;
  }

  return (
    <>
      <Global
        styles={css`
          video::-webkit-media-controls, video::-moz-media-controls {
            display: none !important;
          }
          video {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            scrollbar-width: none;
            display: block;
            width: 100%;
            height: 800px;
            object-fit: cover;
          }
          video::-webkit-scrollbar {
            display: none !important;
          }
          @media (max-width: 600px) {
            video {
              height: 400px;
            }
          }
        `}
      />
      <Box sx={{
        position: 'relative',
        width: '100%',
        height: { xs: '400px', md: '800px' },
        overflow: 'hidden'
      }}>
        {!videoLoaded && (
          <Box sx={{
            width: '100%',
            height: '100%',
            position: 'relative'
          }}>
            <img
              src="/images/VideoCover.png"
              alt="Loading video"
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover'
              }}
            />
            <Skeleton 
              variant="rectangular" 
              width="100%" 
              height="100%" 
              animation="wave"
              sx={{ 
                position: 'absolute',
                top: 0,
                left: 0,
                backgroundColor: 'rgba(255, 255, 255, 0.1)'
              }}
            />
          </Box>
        )}
        {isVideoVisible && (
          <video
            ref={videoRef}
            autoPlay
            loop
            muted
            playsInline
            preload="metadata"
            onLoadedData={handleLoadedData}
            controls={false}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              display: videoLoaded ? 'block' : 'none'
            }}
          >
            <source 
              src="https://firebasestorage.googleapis.com/v0/b/curacao-tours.appspot.com/o/CuracaoToursSmallVideo.mp4?alt=media&token=3a05e149-8fc5-47f9-83c6-51cbf40dfe72" 
              type="video/mp4" 
            />
            Your browser does not support the video tag.
          </video>
        )}
        <Box sx={{
          position: 'absolute',
          top: { xs: '50%', md: '70%' },
          left: { xs: '5%', md: '3%' },
          transform: 'translateY(-50%)',
          color: 'white',
          textAlign: 'left',
          zIndex: 3,
          backgroundColor: 'rgba(255, 255, 255, 0.4)',
          padding: { xs: '10px', md: '20px' },
          borderRadius: '8px',
          width: { xs: '90%', md: 'auto' }
        }}>
          <Typography variant="h3" component="h2" sx={{
            fontWeight: 'bold',
            fontSize: { xs: '2rem', md: '3.5rem' }
          }}>
            Explore Curaçao in<br />
            Ultimate Comfort<br />
            & Style!
          </Typography>
          <Button
            variant="contained"
            sx={{
              mt: { xs: 2, md: 5 },
              backgroundColor: '#1A3D8F',
              color: 'white',
              ':hover': { backgroundColor: '#16357A' },
              fontSize: { xs: '1rem', md: '1.25rem' },
              padding: { xs: '8px 16px', md: '10px 20px' }
            }}
            onClick={handleBookNowClick}
          >
            Book Now
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default VideoBanner;

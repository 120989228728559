import React from 'react';
import { useDataConnect } from '../hooks/useDataConnect';
import { Box, Typography, Paper } from '@mui/material';

const DataConnectDebug = () => {
  const { ready, initialized, error } = useDataConnect();

  return (
    <Paper sx={{ p: 2, m: 2, bgcolor: 'grey.100' }}>
      <Typography variant="h6">DataConnect Status</Typography>
      <Box>
        <Typography>Ready: {String(ready)}</Typography>
        <Typography>Initialized: {String(initialized)}</Typography>
        {error && (
          <Typography color="error">Error: {error}</Typography>
        )}
      </Box>
    </Paper>
  );
};

export default DataConnectDebug; 
import React, { useState } from 'react';
import { Card, Grid, CardContent, Typography, Box } from '@mui/material';
import ErrorBoundary from '../../components/ErrorBoundary';
import MonthlyPaxChart from '../../components/dataconnectDashboard/MonthlyPaxChart';
import { useFirestoreCache } from '../../hooks/useFirestoreCache';
import MetadataDisplay from '../../components/dataconnectDashboard/MetadataDisplay';
import FilteredBookingChart from '../../components/dataconnectDashboard/FilteredBookingChart';
import DataConnectDebug from '../../components/DataConnectDebug';

const SardonyxDashboard = () => {
  const { clearCache } = useFirestoreCache();
  const [selectedFilters, setSelectedFilters] = useState({
    agents: [],
    types: [],
    options: [],
    locations: []
  });
  const [showFilteredChart, setShowFilteredChart] = useState(false);

  React.useEffect(() => {
    clearCache();
  }, []);

  const handleRunQuery = (filters) => {
    console.log('Running query with filters:', JSON.stringify(filters, null, 2));
    setSelectedFilters(filters);
    setShowFilteredChart(true);
  };

  return (
    <Box sx={{ flexGrow: 1, padding: 3 }}>
      <DataConnectDebug />

      <Box sx={{ mb: 3 }}>
        <Typography variant="h4" gutterBottom component="div">
          Sardonyx Analytics Dashboard
        </Typography>
        <Typography variant="subtitle1" color="text.secondary" gutterBottom>
          Real-time analytics and data visualization for Sardonyx
        </Typography>
      </Box>

      <Grid container spacing={3}>
        {/* Monthly Overview Chart - Now at the top */}
        <Grid item xs={12}>
          <Card 
            sx={{ 
              padding: '20px', 
              boxShadow: 3, 
              backgroundColor: '#ffffff',
              minHeight: '600px',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <CardContent sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
              <ErrorBoundary fallback={<div>Error loading chart component</div>}>
                <MonthlyPaxChart />
              </ErrorBoundary>
            </CardContent>
          </Card>
        </Grid>

        {/* Metadata Display with Filters */}
        <Grid item xs={12}>
          <Card sx={{ padding: '20px', boxShadow: 3, backgroundColor: '#ffffff' }}>
            <CardContent>
              <ErrorBoundary fallback={<div>Error loading metadata</div>}>
                <MetadataDisplay 
                  onFiltersChange={setSelectedFilters} 
                  onRunQuery={handleRunQuery}
                />
              </ErrorBoundary>
            </CardContent>
          </Card>
        </Grid>

        {/* Filtered Chart - Only shown after query is run */}
        {showFilteredChart && (
          <Grid item xs={12}>
            <Card sx={{ padding: '20px', boxShadow: 3, backgroundColor: '#ffffff' }}>
              <CardContent>
                <ErrorBoundary fallback={<div>Error loading filtered chart</div>}>
                  <FilteredBookingChart filters={selectedFilters} />
                </ErrorBoundary>
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default SardonyxDashboard;
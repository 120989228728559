// Function to convert a title to a URL-friendly slug
export const createUrlSlug = (title) => {
  if (!title) return '';
  
  return title
    .toLowerCase() // Convert to lowercase
    .replace(/[^a-z0-9\s-]/g, '') // Remove special characters except spaces and hyphens
    .trim() // Remove leading/trailing spaces
    .replace(/\s+/g, '-') // Replace spaces with hyphens
    .replace(/-+/g, '-'); // Replace multiple consecutive hyphens with a single hyphen
};

// Function to extract ID from URL path
export const extractTourId = (path) => {
  // Match the ID at the end of the URL after the last hyphen
  const matches = path.match(/\/tour\/.*-(\d+)$/);
  return matches ? matches[1] : null;
}; 